import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { API_CONFIG, _getURL } from 'config/API_CONFIG';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-double-auth',
  templateUrl: './double-auth.component.html',
  styleUrls: ['./double-auth.component.css','../login/login.component.css']
})
export class DoubleAuthComponent implements OnInit {
  code:any;
  isValid = true
  errorMsg="";
  successMsg=true;
  constructor(
    private router:Router,
    private recaptchaV3Service: ReCaptchaV3Service,private http: HttpClient,
  private authService:AuthService      ) {  }

  ngOnInit(): void {
  }
  setCodeAuthDouble(){
    this.successMsg=false;
    this.errorMsg = ""
    if(!this.code || this.code == undefined || this.code == null){
      return false;
    }
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      if (token) {
        this.http.post(_getURL(API_CONFIG.recaptchaverification), { token: token })
          .subscribe((response: any) => {
            if (response && response.success && response.score >= 0.5) {
              this.suivreLoginAdmin()
              return true;
            } else {
              this.isValid = false
              this.errorMsg = "Erreur de recaptcha"
              setTimeout(() => {
                  this.isValid = true
                },
                5000);
              return false;
            }
            // Handle the verification response from the backend
          });

      }
    }, error => {
      this.isValid = false
      this.errorMsg = "Erreur de recaptcha"
      setTimeout(() => {
          this.isValid = true
        },
        5000);
      return false;
    });
   
  }
  suivreLoginAdmin(){
  if(this.code == undefined || !this.code || isNaN(this.code) || this.code < 100000 || this.code > 999999)
    {
      this.isValid = false
      this.errorMsg = "Le code d'authentification doit être un nombre à 6 chiffres entre 100000 et 999999.";
      setTimeout(() => {
          this.isValid = true
        },
          5000);
      return false;
    }
      this.authService.authenticateAdminDoubleAuth({codeValidation:this.code}).subscribe((data:any) => {
        if(data.success){
          this.authService.storeAdminData(data.token, data.admin);
          if(data.admin.email === "gestion-events-med@voguelconsulting.com"){
            this.router.navigate(['events-v2']);
            return true
          }else{
            this.router.navigate(['accueil']);
            return true
          }
        } else {
          this.isValid = false
          this.errorMsg =data.msg
          setTimeout(() => {
            this.isValid = true
          },
            5000);
          return false;
        }
      },
      err => {
        return false;
      });
    }
}
