export const API_CONFIG = {
  apiPathBase: '/api',
 //apiPathBase: 'http://localhost:3012/api',
 patientByEmail: '/patientByEmail',
 register: '/register',
 profile: '/profile',
 authenticateAdmin: '/authenticate/admin',
 profilAdmin:'/profilAdmin',
 medecin:'/medecin',
 patients:'/patients',
 rdvs:'/rdvs',
 secretaire:'/secretaire',
 agenda:'/agenda',
 upload:'/upload',
 document:'/document',
 event:'/event',
 evenement:'/evenements',
 pays:'/pays',
 image:'/image',
 admin:'/admin',
 conseil:'/conseil',
 notification:'/notification',
 medecinScraped:'/medecinScraped',
 medicament:'/medicament',
 article:'/article/',
 uploadArticle:'/uploads/articles',
 recaptchaverification:'/verify-recaptcha',
 secretaires:'/secretaire',

};
export function _getURL(serviceName){
 return API_CONFIG.apiPathBase + serviceName;
}
export const uploadFolderRootAdmin = '/data/files/admin/'
export const uploadFolderRootEvenement = '/data/files/evenement/'
export const uploadFolderRootMedecin = '/data/files/medecin/'
export const rootModelsFichierPatients = '/data/files/models/ficheModelPatients.xlsx'
