import { Component, OnInit } from '@angular/core';
import {AuthService} from 'src/app/services/auth/auth.service';
import { Router,ActivatedRoute}from '@angular/router';
import { MedecinService } from 'src/app/services/medecin/medecin.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
@Component({
  selector: 'app-gestion-roles-medecins',
  templateUrl: './gestion-roles-medecins.component.html',
  styleUrls: ['./gestion-roles-medecins.component.css','../../medecins/medecins/medecins.component.css']
})
export class GestionRolesMedecinsComponent implements OnInit {

  admin:any;
  RolesAdmin:any[]=[];
  gestionRdvs:any;
  gestionPatient:any;
  gestionAgenda:any;
  gestionDocuments:any;
  gestionCertificats:any;
  gestionOrdonnaces:any;
  gestionMedecins:any;
  gestionEvenements:any;
  gestionSalleAttente:any;
  medecin:any;
  constructor(private medecinService:MedecinService, private authService:AuthService,private activatedRoute:ActivatedRoute ,
    private router:Router) {
      this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if(!this.admin.roles.gestionMedecins){
        this.router.navigate(['/notFound']);
        return false;
      }
      },
      err => {
      console.log(err);
      return false;
      });
  }
      ngOnInit(){
        let el = document.getElementById('scrolltoverify');
      el.scrollIntoView({ behavior: "smooth" });
      let params: any = this.activatedRoute.snapshot.params;
      this.medecinService.getMedecinById(params.id).subscribe(medecin => {
          this.medecin = medecin;
          this.gestionRdvs = this.medecin.roles.gestionRdvs;
          this.gestionPatient= this.medecin.roles.gestionPatient;
          this.gestionAgenda= this.medecin.roles.gestionAgenda;
          this.gestionDocuments= this.medecin.roles.gestionDocuments;
          this.gestionCertificats= this.medecin.roles.gestionCertificats;
          this.gestionOrdonnaces= this.medecin.roles.gestionOrdonnaces;
          this.gestionMedecins= this.medecin.roles.gestionMedecins;
          this.gestionEvenements= this.medecin.roles.gestionEvenements;
          this.gestionSalleAttente=this.medecin.roles.gestionSalleAttente;
        },
        err => {
          console.log(err);
          return false;
        });
        }
  // modifier les rôles d'un médecin
  modifierRoles(){
    let params: any = this.activatedRoute.snapshot.params;
  var newMedecin = {
    _id:params.id,
    roles:{
      gestionRdvs:this.gestionRdvs,
      gestionPatient:this.gestionPatient,
      gestionAgenda:this.gestionAgenda,
      gestionDocuments:this.gestionDocuments,
      gestionCertificats:this.gestionCertificats,
      gestionOrdonnaces:this.gestionOrdonnaces,
      gestionMedecins:this.gestionMedecins,
      gestionEvenements:this.gestionEvenements,
      gestionSalleAttente:this.gestionSalleAttente
    }
  
  }
  this.medecinService.updateMedecin(newMedecin).subscribe(medecin => {
      this.medecin = medecin;
      Swal.fire( {
          width: 500,
          background: '#e1e1e1',
          timer: 3000,
          html: '<div class="row">' +
            '<div class="col-3">' +
            '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
            '</div>' +
            '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Les rôles ont été MODIFIÉ avec succès</h4></div>' +
            '</div>',
          showConfirmButton: false
        }
      );
      this.router.navigate(['/medecins']);
  });
  
  }
  
  }
  
