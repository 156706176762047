<br><br>
<div class="">
  <img src="assets/medivisto-yellow.png" alt="" class="logo-admin">
</div>
<br><br><br>
<div class="row">
  <div class="col-12 col-sm-12 col-md-12  col-lg-3">
    <img src="assets/doctors.png" alt="" class="img-login-admin">

  </div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-9">
    <div class="login-page">
      <div class="form">
        <div id="exTab2" class="">

          <div class="tab-content" style="padding-top: 30px">
            <div class="tab-pane active" id="1">
              <form  class="login-form" (submit)="setCodeAuthDouble()">
                <p *ngIf="successMsg" style="color: #4a4949;">Un code d'authentification a été envoyé à votre adresse email. Veuillez le vérifier et entrer le code pour compléter votre connexion.</p>
                <p *ngIf="errorMsg" style="color: red;">{{errorMsg}}</p>
                <input type="number" placeholder="Code d'authentification de la connexion" name="code" [(ngModel)]="code" min="100000" max="999999"  required />
                <input type="submit"  class="btn" style="background-color: #E5B92C;font-size: 17px;font-weight: bold;color: white;" [attr.disabled]="!isValid ? true : null" value="Envoyer">
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
