import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// import { tokenNotExpired } from 'angular2-jwt';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class AuthService {
  authToken: any;
  patient: any;
  medecin: any;
  admin: any;
  user: any;
  constructor(private http: HttpClient) {
  }

  authenticateAdmin(admin) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(_getURL(API_CONFIG.authenticateAdmin), admin, { headers: headers })
      
  }
  authenticateAdminDoubleAuth(admin) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(_getURL(API_CONFIG.authenticateAdmin)+'/new/version/code', admin, { headers: headers })
      
  }

  getProfileAdmin() {
    this.loadToken();
    const headers = new HttpHeaders({
      'Authorization': this.authToken,
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.profilAdmin), { headers: headers })
      
  }
  loadToken() {
    const token = localStorage.getItem('id_token_adminMedivisto');
    this.authToken = token;
  }
  loggedIn() {
    // return tokenNotExpired();
    return true;
  }
  // get patient with his email
  getPatient(patient) {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.patientByEmail), JSON.stringify(patient), { headers: headers })
      
  }
  //save data in localstorage:token+patient data
  storePatientData(token, patient) {
    localStorage.setItem('id_token_adminMedivisto', token);
    localStorage.setItem('patient', JSON.stringify(patient));
    this.authToken = token;
    this.patient = patient;
  }

  //save data in localstorage:token+medecin data
  storeMedecinData(token, medecin) {
    localStorage.setItem('id_token_adminMedivisto', token);
    localStorage.setItem('medecin', JSON.stringify(medecin));
    this.authToken = token;
    this.medecin = medecin;
  }
  storeAdminData(token, admin) {
    localStorage.setItem('id_token_adminMedivisto', token);
    localStorage.setItem('admin', JSON.stringify(admin));
    this.authToken = token;
    this.admin = admin;
  }
  //logout
  logout() {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
  }

  //logout medecin
  logoutMedecin() {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
  }
}
