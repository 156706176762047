import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AdminsComponent } from './admins/admins/admins.component';
import { GestionRolesAdminComponent } from './admins/gestion-roles-admin/gestion-roles-admin.component';
import { AgendaComponent } from './agenda/agenda.component';
import { AjoutArticleComponent } from './articles/ajout-article/ajout-article.component';
import { ArticlesComponent } from './articles/articles/articles.component';
import { UpdateArticleComponent } from './articles/update-article/update-article.component';
import { BackofficeComponent } from './backoffice.component';
import { ConseilsComponent } from './conseils/conseils/conseils.component';
import { DetailsConseilComponent } from './conseils/details-conseil/details-conseil.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AjoutEvenementsComponent } from './events/ajout-evenements/ajout-evenements.component';
import { DetailsEvenementsComponent } from './events/details-evenements/details-evenements.component';
import { EventsComponent } from './events/events/events.component';
import { UpdateEvenementsComponent } from './events/update-evenements/update-evenements.component';
import { AjoutMedecinIntegreComponent } from './medecins/ajout-medecin-integre/ajout-medecin-integre.component';
import { AjoutMedecinComponent } from './medecins/ajout-medecin/ajout-medecin.component';
import { DetailsMedecinsComponent } from './medecins/details-medecins/details-medecins.component';
import { GestionRolesMedecinsComponent } from './medecins/gestion-roles-medecins/gestion-roles-medecins.component';
import { MedecinsComponent } from './medecins/medecins/medecins.component';
import { UpdateMedecinIntegreComponent } from './medecins/update-medecin-integre/update-medecin-integre.component';
import { UpdateMedecinComponent } from './medecins/update-medecin/update-medecin.component';
import { DetailsMedicamentsComponent } from './medicaments/details-medicaments/details-medicaments.component';
import { MedicamentsComponent } from './medicaments/medicaments/medicaments.component';
import { AjouterPatientsMedComponent } from './patients/ajouter-patients-med/ajouter-patients-med.component';
import { PatientsMedecinComponent } from './patients/patients-medecin/patients-medecin.component';
import { PatientsComponent } from './patients/patients/patients.component';
import { UpdatePatientComponent } from './patients/update-patient/update-patient.component';
import { ProfilComponent } from './profil/profil.component';
import { EventV2Component } from './event-v2/event-v2.component';
import { ArchivedEventComponent } from './event-v2/archived-event/archived-event.component';
import { AddEventComponent } from './event-v2/add-event/add-event.component';
import { UpdateEventComponent } from './event-v2/update-event/update-event.component';
import { AuthAgendaGuard } from 'src/app/guards/auth.agenda.guard';
import { AuthAdminGuard } from 'src/app/guards/auth.admin.guard';
import { NotFoundComponent } from '../not-found/not-found.component';
import { AuthEventGuard } from 'src/app/guards/auth.event.guard';
import { AuthConseilGuard } from 'src/app/guards/auth.conseil.guard';
import { SecretairesComponent } from './secretaires/secretaires/secretaires.component';
import { AjoutSecretaireComponent } from './secretaires/ajout-secretaire/ajout-secretaire.component';
import { UpdateSecretaireComponent } from './secretaires/update-secretaire/update-secretaire.component';


const routes: Routes = [
  {
    path: '', component: BackofficeComponent,
    children: [
      {path:'accueil',component:DashboardComponent,canActivate:[AuthGuard]},
      { path: 'agenda', component: AgendaComponent,canActivate:[AuthAgendaGuard] },
      { path: 'admins', component: AdminsComponent,canActivate:[AuthAdminGuard] },
      { path: 'medicaments', component: MedicamentsComponent },
      { path: 'medicament/details/:id', component: DetailsMedicamentsComponent },
      { path: 'patients', component: PatientsComponent },
      //{ path: 'ajoutPatient', component: AjouterPatientsMedComponent },
      { path: 'patient/modifier/:id', component: UpdatePatientComponent },
      { path: 'medecins', component: MedecinsComponent },
      {path:'medecin/details/:id',component:DetailsMedecinsComponent},
      {path:'gestionRolesMedecins/:id',component:GestionRolesMedecinsComponent},
      {path:'patients/medecin/:id',component:PatientsMedecinComponent},
      { path: 'ajoutMedecin', component: AjoutMedecinComponent },
      { path: 'ajoutMedecinIntegre', component: AjoutMedecinIntegreComponent },
      {path:'medecin/modifier/:id',component:UpdateMedecinComponent},
      {path:'medecin/integre/modifier/:id',component:UpdateMedecinIntegreComponent},
      //{path:'events',component:EventsComponent},
      {path:'events-v2',component:EventV2Component, canActivate:[AuthEventGuard]},
      { path: "event/ajout", component: AddEventComponent, canActivate:[AuthEventGuard] },
       { path: "event/modification/:id", component: UpdateEventComponent, canActivate:[AuthEventGuard] },
       { path: "event/archives", component: ArchivedEventComponent, canActivate:[AuthEventGuard] },
      {path:'nouveau/evenement',component:AjoutEvenementsComponent},
      {path:'evenement/details/:id',component:DetailsEvenementsComponent},
      {path:'evenement/:id',component:UpdateEvenementsComponent,},
      { path: 'conseils', component: ConseilsComponent },
      { path: 'conseil/details/:id', component: DetailsConseilComponent },
      {path:'gestionRolesAdmin/:id',component:GestionRolesAdminComponent,canActivate:[AuthAdminGuard]},
      {path:'profil',component:ProfilComponent},
      {path:'articles',component:ArticlesComponent,canActivate:[AuthConseilGuard]},
      {path:'ajoutArticle',component:AjoutArticleComponent,canActivate:[AuthConseilGuard]},
      {path:'article/modifier/:id',component:UpdateArticleComponent,canActivate:[AuthConseilGuard]},
            
      {path:'secretaires/medecin/:id',component:SecretairesComponent},
      {path:'ajoutSecretaire/:id',component:AjoutSecretaireComponent},
      {path:'secretaire/modifier/:id',component:UpdateSecretaireComponent},
      

    ]

  },
  {path: 'notFound', component: NotFoundComponent},
  { path: '**', component: NotFoundComponent }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BackofficeRoutingModule { }
